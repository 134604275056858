import React, { useState, useRef, useEffect } from "react";
import { useAppDispatch } from "../redux/store";
import { withFormik, Form, Field } from "formik";
import { addPatient } from "../redux/OsteroCheckSlice";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Navbar from "../components/Navbar";
import Sidebar from "../components/Sidebar";
import FileUploadForm from "../components/DragDrop";
import { testCases } from "../constant";
import { TypeAnimation } from 'react-type-animation';

const PatientInfo = () => {
  const patientData = useSelector(
    (state) => state.OsteroCheck.patientData
  );

  const fileInputRef = useRef(null);
  const report = useSelector((state) => state.OsteroCheck.report);
  const appDispatch = useAppDispatch();
  const [loadingWidth, setLoadingWidth] = useState(0);
  const navigate = useNavigate();
  const [selectedFiles, setSelectedFiles] = useState([]);

  const handleSubmit = (values) => {
    appDispatch(addPatient(values));
  };




  const PatientInfoForm = (props) => {
    const { touched, errors, isSubmitting, setFieldValue, values, handleSubmit, isValid, setTouched, validateForm } = props;
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [selectedTestCase, setSelectedTestCase] = useState(null);
    const [previewImageUrl, setPreviewImageUrl] = useState(null);

    const RequiredAsterisk = () => (
      <span className="text-red-500 ml-1">*</span>
    );

    const handleTestCaseSelection = (testCaseNumber) => {
      if (selectedTestCase === testCaseNumber) {
        clearFormData();
        setSelectedTestCase(null);
        setSelectedFiles([]);
        setPreviewImageUrl(null);
      } else {
        setSelectedTestCase(testCaseNumber);
        const testCaseData = testCases[values.mod_study][testCaseNumber];

        let touchedFields = {};
        let newValues = { ...values };

        Object.keys(testCaseData).forEach(key => {
          if (key !== 'file' && key !== 'url') {
            newValues[key] = testCaseData[key];
            touchedFields[key] = true;
          }
        });

        setTouched(touchedFields);

        Object.keys(newValues).forEach(key => {
          setFieldValue(key, newValues[key]);
        });

        props.validateForm(newValues);

        const file = new File([""], testCaseData.file, { type: "application/zip" });
        setSelectedFiles([{
          id: file.name,
          name: file.name,
          size: file.size,
          type: file.type,
          url: URL.createObjectURL(file)
        }]);
        setPreviewImageUrl(testCaseData.url);
      }
    };

    const clearFormData = () => {
      console.log("clearFormData called");
      const clearedValues = {
        name: "",
        sex: "",
        age: "",
        phone_no: "",
        history: "",
        emergency: false,
      };

      Object.keys(clearedValues).forEach(key => {
        setFieldValue(key, clearedValues[key]);
      });

      setTouched({});
      props.validateForm(clearedValues);
    };



    const handleModStudyChange = (e) => {
      const newModStudy = e.target.value;
      setFieldValue("mod_study", newModStudy);
      setSelectedTestCase(null);

      setFieldValue("name", "");
      setFieldValue("sex", "");
      setFieldValue("age", "");
      setFieldValue("phone_no", "");
      setFieldValue("history", "");
      setFieldValue("emergency", false);

      setSelectedFiles([]);
    };

    const renderTestCases = () => {
      if (!values.mod_study || !testCases[values.mod_study]) return null;

      const cases = testCases[values.mod_study];
      const caseNumbers = Object.keys(cases);

      return (
        <div className="w-2/4 md:w-full px-3 mb-3 md:mb-0 sm:mb-0">
          <label
            className="block tracking-wide text-gray-500 text-sm text-left mb-1 sm:mb"
            htmlFor="testCase">
            Test cases (optional)
          </label>
          <div className="flex flex-wrap items-center mt-4">
            {caseNumbers.slice(0, 2).map((caseNumber) => (
              <label key={caseNumber} className="flex gap-2 mr-3 mb-2">
                <input
                  type="radio"
                  name="testCase"
                  className="radio checked:bg-[#4359A5]"
                  checked={selectedTestCase === parseInt(caseNumber)}
                  onChange={() => handleTestCaseSelection(parseInt(caseNumber))}
                />
                Test case {caseNumber}
              </label>
            ))}

            {caseNumbers.length === 3 ? (
              <label className="flex gap-2 mr-4 mb-2">
                <input
                  type="radio"
                  name="testCase"
                  className="radio checked:bg-[#4359A5]"
                  checked={selectedTestCase === parseInt(caseNumbers[2])}
                  onChange={() => handleTestCaseSelection(parseInt(caseNumbers[2]))}
                />
                Test case {caseNumbers[2]}
              </label>
            ) : caseNumbers.length > 3 && (
              <div className="relative">
                <select
                  className="block appearance-none text-[14px] w-full border border-gray-200 text-gray-700 py-2 px-3 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  value={selectedTestCase > 2 ? selectedTestCase : ""}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (value) handleTestCaseSelection(parseInt(value));
                  }}
                >
                  <option value="" disabled>More cases</option>
                  {caseNumbers.slice(2).map((caseNumber) => (
                    <option key={caseNumber} value={caseNumber}>
                      Test case {caseNumber}
                    </option>
                  ))}
                </select>
                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                  <svg
                    className="fill-current h-4 w-4"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20">
                    <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                  </svg>
                </div>
              </div>
            )}
          </div>
        </div>
      );
    };


    return (
      <div className="flex">
        <Form onSubmit={handleSubmit} className="w-full text-center">
          <div className="flex flex-wrap -mx-3 mb-6 sm:mb-0">
            <div className="w-2/4 md:w-full px-3 mb-3 md:mb-0 sm:mb-0">
              <label
                className="block tracking-wide text-gray-500 text-sm text-left mb-1 sm:mb"
                htmlFor="sex">
                Select the study<RequiredAsterisk />
              </label>
              <div className="relative">
                <Field
                  as="select"
                  name="mod_study"
                  className="block appearance-none text-[14px] w-full border border-gray-200 mb-3 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id="mod_study"
                  onChange={handleModStudyChange}>
                  <option disabled value=""> - </option>
                  <option value="CT Abdomen Plain and Contrast">CT Abdomen Plain and Contrast</option>
                  <option value="CT brain">CT Brain plain</option>
                  <option value="CT KUB">CT KUB</option>
                  <option value="CT PNS">CT PNS</option>
                  <option value="CT Temporal Bone">CT Temporal Bone</option>
                  <option value="CT Throax">CT Throax</option>
                  <option value="ChestR">XRAY Radiograph Chest</option>
                  <option value="KXR">XRAY Radiograph knee</option>
                  <option value="SXR">XRAY Radiograph Spine</option>
                  <option value="lifesaver">Life saver</option>
                  <option value="MRI Knee">MRI Knee</option>
                  <option value="mribrain">MRI Brain</option>
                  <option value="MRI spine">Contrast MRI Spine</option>
                  <option value="Mmrils">Measure MRI Lumbosacral Spine</option>
                  <option value="Mmrics">Measure MRI Cervical Spine</option>
                  <option value="Mxraycsm">Measure XRay Cervical Spine measurement</option>
                  <option value="Mxraydsm">Measure XRay Dorsal Spine measurement</option>
                  <option value="Mxraylsm">Measure XRay Lumbar Spine measurement</option>
                  <option value="Mcta">Measure CT Abdomen</option>
                </Field>
                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                  <svg
                    className="fill-current h-4 w-4"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20">
                    <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                  </svg>
                </div>
              </div>
            </div>
            {renderTestCases()}


          </div>

          <div className="flex flex-wrap -mx-3 mb-6  text-left">
            <div className="w-2/4 px-3 mb-6 md:mb-0 text-left sm:mb-0">
              <label
                className="block tracking-wide text-gray-500 text-sm  mb-1"
                htmlFor="name">
                Patient Name<RequiredAsterisk />
              </label>
              <Field
                type="text"
                name="name"
                className="appearance-none text-[14px] block w-full  text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                id="name"
                placeholder="Enter Patient Name"
              />
              {touched.name && errors.name && (
                <p className="text-red-500 text-xs italic">{errors.name}</p>
              )}
            </div>
            <div className="w-2/4 md:w-1/4 px-3 mb-3 md:mb-0">
              <label
                className="block tracking-wide text-gray-500 text-sm  mb-1"
                htmlFor="sex">
                Gender<RequiredAsterisk />
              </label>
              <div className="relative">
                <Field
                  as="select"
                  name="sex"
                  className="block appearance-none w-full text-[14px]  border border-gray-200 mb-3 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id="sex">
                  <option disabled value="">
                    {" "}
                    -{" "}
                  </option>
                  <option value="Male" >Male</option>
                  <option value="Female">Female</option>
                  <option value="Other">Other</option>
                </Field>
                {touched.sex && errors.sex && (
                  <p className="text-red-500 text-xs italic">{errors.sex}</p>
                )}

                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                  <svg
                    className="fill-current h-4 w-4"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20">
                    <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                  </svg>
                </div>
              </div>
            </div>

          </div>
          <div className="flex flex-wrap -mx-3 mb-6 text-sm  text-left">
            <div className="w-2/4 md:w-1/4 px-3 mb-3 md:mb-0">
              <label className="block tracking-wide text-gray-500 text-left text-sm  mb-1" htmlFor="age">
                Age<RequiredAsterisk />
              </label>
              <Field
                type="number"
                min="1"
                max="120"
                name="age"
                className="appearance-none text-[14px] block w-full  text-gray-700 border mb-3 border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                id="age"
                placeholder="Age"
              />
              {touched.age && errors.age && (
                <p className="text-red-500 text-xs italic">{errors.age}</p>
              )}
            </div>
            <div className="w-2/4 md:w-2/4 px-3 mb-3 md:mb-0">
              <label
                className="block tracking-wide text-gray-500 text-sm mb-1"
                htmlFor="phone_no">
                Phone Number
              </label>
              <Field
                type="text"
                name="phone_no"
                className="appearance-none block w-full  text-gray-700 border border-gray-200 mb-3 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                id="phone_no"
                placeholder="Phone Number"
              />
              {touched.phone_no && errors.phone_no && (
                <p className="text-red-500 text-xs italic">{errors.phone_no}</p>
              )}
            </div>
          </div>
          {/* <div className="w-2/4 md:w-full mb-3 md:mb-0 sm:mb-0 flex">
          <div className="inline-flex items-center">
            <label
              className="relative flex items-center rounded-full p-3 cursor-pointer"
              htmlFor="emergency"
              data-ripple-dark="true"
            >
              <Field
                type="checkbox"
                id="emergency"
                name="emergency"
                className="before:content[''] peer relative h-5 w-5 cursor-pointer appearance-none rounded-md border border-blue-gray-200 transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-12 before:w-12 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-blue-gray-500 before:opacity-0 before:transition-opacity checked:border-[#4359A5] checked:bg-[#4359A5] checked:before:bg-[#4359A5] hover:before:opacity-10"
              />
              <div className="pointer-events-none absolute top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 text-white opacity-0 transition-opacity peer-checked:opacity-100">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-3.5 w-3.5"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  stroke="currentColor"
                  strokeWidth="1"
                >
                  <path
                    fillRule="evenodd"
                    d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                    clipRule="evenodd"
                  ></path>
                </svg>
              </div>
            </label>
            <label
              className="mt-px cursor-pointer select-none font-light text-[#6b7280]"
              htmlFor="emergency"
            >
              Is this an emergency case?
            </label>
          </div>
        </div> */}
          <div className="w-2/4 md:w-full mb-3 md:mb-0 sm:mb-0 flex">
          </div>

          <div className="flex flex-wrap -mx-3  text-left">
            <div className="w-full px-3">
              <label
                className="block tracking-wide text-gray-500 text-sm mb-1"
                htmlFor="history">
                Patient History<RequiredAsterisk />
              </label>
              <Field
                as="textarea"
                id="history"
                className="appearance-none text-[14px] block w-full  text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                name="history"
                rows={4}
              />
              {touched.history && errors.history && (
                <p className="text-red-500 text-xs italic">{errors.history}</p>
              )}
            </div>
          </div>

          <button type="submit" style={{ display: 'none' }} />
        </Form>
        <FileUploadForm
          onSubmit={() => {
            if (fileInputRef.current) {
              fileInputRef.current.click();
            }
          }}
          fileInputRefs={fileInputRef}
          patientFormValues={values}
          debug={{ touched, values }}
          preSelectedFiles={selectedFiles}
          previewImageUrl={previewImageUrl}
        />
      </div>
    );
  };

  const PatientInfoFormik = withFormik({
    mapPropsToValues: (props) => {
      return {
        name: patientData.data.name,
        sex: patientData.data.sex,
        age: 0,
        mod_study: patientData.data.mod_study || '',
        history: patientData.data.history || "NA",
        emergency: false,
      };
    },

    validate: (values) => {
      const errors = {};
      if (!values.name) {
        errors.name = "Please fill out this field.";
      }
      if (!values.sex) {
        errors.sex = "Please fill out this field.";
      }
      if (!values.age || values.age <= 0 || values.age > 120) {
        errors.age = "Please enter a valid age between 1 and 120.";
      }
      if (!values.mod_study) {
        errors.mod_study = "Please select a modality.";
      }
      if (!values.history) {
        errors.history = "Please provide patient history.";
      }
      return errors;
    },

    handleSubmit: (values, { setSubmitting }) => {
      handleSubmit(values);
      setSubmitting(false);
    }
  })(PatientInfoForm);

  useEffect(() => {
    if (report.loading) {
      setTimeout(() => {
        if (loadingWidth < 96) {
          setLoadingWidth(loadingWidth + 4);
        }
      }, 500);
    }
  }, [report.loading, loadingWidth]);

  return (
    <div className="flex flex-col h-screen w-screen bg-[#f5f6fa]">
      <div className="border-b border-solid bg-white">
        <Navbar />
      </div>
      <div className="flex flex-1 overflow-hidden">
        <Sidebar />
        <main className="flex-1 overflow-y-auto p-4 relative">
          {report.loading && (
            <div className="absolute inset-0 flex flex-col items-center justify-center z-20 bg-[#e7e7e7] bg-opacity-5 backdrop-filter backdrop-blur-sm">
              <iframe src="https://lottie.host/embed/1329de50-00a6-4ecf-8436-23a4366023ef/9c9C0i38kl.json" className="w-1/3 h-1/4"></iframe>
              <TypeAnimation
                sequence={[
                  'Initializing AI model...',
                  500,
                  'Loading data...',
                  600,
                  'Processing...',
                  500,
                  'Analyzing...',
                  600,
                  'Generating output...',
                  500,
                  'AI processing complete!',
                  1000,
                  () => {
                    console.log('AI processing sequence completed');
                  },
                ]}
                wrapper="span"
                cursor={true}
                repeat={Infinity}
                style={{ fontSize: '1.5em', display: 'inline-block', color: '#003049' }}
              />
            </div>
          )}

          <div className="bg-white rounded-xl shadow-[4px_4px_4px_rgba(0,0,0,0.2)] border border-gray-300 overflow-y-auto relative z-10 h-full fade-in">
            <div className="py-6 pr-[0.8rem] px-10 sm:px-4">
              <div className="flex-col flex mb-8">
                <p className="font-semibold mt-5 text-left tracking-[1px] text-lg">
                  Patient Information
                </p>
                <label className="text-[12px] tracking-wide text-[#5C5C5C] opacity-90">
                  Please fill all the mandatory fields given below
                </label>
              </div>
              <PatientInfoFormik />
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};



export default PatientInfo;